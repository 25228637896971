import { Button } from '@material-ui/core';
import { graphql, Link } from 'gatsby';
import React, { useEffect } from 'react';

import PhoneHeader from 'components/Headers/PhoneHeader';
import StickyHeader from 'components/Headers/StickyHeader';
import SEO from 'components/SEO';
import EduGraph from 'images/EduGraph.svg';
import { ContentfulDiscussion } from 'models/contentfulModels';

import Navigation from './Navigation';

type Props = {
  data: { contentfulDiscussions: ContentfulDiscussion };
}

const Discussion = (props: Props): JSX.Element => {

  const { swarmcheckId, topic, title, materialReference, disableHistory } = props.data.contentfulDiscussions;


  return (
    <div className="swarmcheck">
      <SEO title={title} />
      <StickyHeader discussion />
      <PhoneHeader discussion />
      <Navigation topic={topic} title={title} />
      {materialReference 
        ? <Link 
          to={`/materiały/${materialReference.slug}`} 
          className="swarmcheck__link"
        >
          <Button 
            color="inherit" 
            size="large" 
            variant="outlined"
            startIcon={<EduGraph />}
          >
            DOWIEDZ SIĘ WIĘCEJ
          </Button>
        </Link>
        : null
      }
      <iframe 
        title={`${swarmcheckId}`}
        style={{ 
          marginTop: '110px',
          height: 'calc(100% - 110px)',
        }}
        width="100%"
        src={`${process.env.SWARMCHECK_PV3_APP_URL}/widget/${swarmcheckId}/pl/SON?${disableHistory ? 'disableHistory=' + disableHistory : ''}&tutorialLink=https://youtu.be/rV2f8Ii1JpQ`}
        frameBorder={0}
      >
      </iframe>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulDiscussions(slug: {eq: $slug}) {
      swarmcheckId
      topic
      title
      disableHistory
      materialReference {
        slug
      }
    }
  }
`;

export default Discussion;