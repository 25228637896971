import { ArrowForwardIos, Home } from '@material-ui/icons';
import { Link } from 'gatsby';
import React from 'react';

type Props = {
  topic: string;
  title: string;
}

const Navigation = (props: Props): JSX.Element => {

  return (
    <div className="navigation">
      <Link to="/"><Home /></Link>
      <ArrowForwardIos fontSize="inherit" />
      <Link to="/dyskusje">Lista dyskusji</Link>
      <ArrowForwardIos fontSize="inherit" />
      <Link to="/dyskusje/#list" state={{ topic: props.topic }}>{props.topic}</Link>
      <ArrowForwardIos fontSize="inherit" />
      <h3>{props.title}</h3>
    </div>
  );
};

export default Navigation;